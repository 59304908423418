<template>
    <div>
        <b-card border-variant="success" header-border-variant="success" header-bg-variant="success" header-tag="header"
            header-text-variant="light" class="mt-3">
            <template #header>
                <div class="d-flex flex-row justify-content-between pt-0 pb-0 mt-0 mb-0">
                    <h5 class="mb-0 mt-2 monoTitle">Invoice</h5>
                </div>
            </template>



            <b-button variant="outline-success" @click="showInvoice" class="mb-2">
                <font-awesome-icon icon="file" scale=".8"></font-awesome-icon> View Invoice
            </b-button>


            <!-- Money Line Items -->
            <b-list-group>

                <b-list-group-item v-for="lineItem in money" :key="lineItem._id" class="d-flex">
                    <span class="flex-grow-1">
                        <!-- Contract Price -->
                        <div v-if="lineItem.description == 'Contract Price'">
                            <div v-if="lineItem.custDesc">
                                <b>{{ lineItem.custDesc }}</b>
                            </div>
                            <div v-else>
                                <b>{{ lineItem.description }}</b>
                            </div>
                        </div>
                        <!-- End Contract Price -->

                        <!-- Other Items-->
                        <div v-else>
                            <div v-if="lineItem.debit.toLowerCase() == 'credit'" class="d-inline">
                                <div v-if="lineItem.description != 'Deposit'" class="d-inline">
                                    Payment: {{ lineItem.description }}
                                </div>
                                <div v-else class="d-inline">
                                    {{ lineItem.description }}
                                </div>
                            </div>
                            <div v-else class="d-inline">
                                {{ lineItem.description }}
                            </div>
                        </div>
                        <!-- end Other Items-->

                        <div v-if="lineItem.note">
                            <small>{{ lineItem.note }}</small>
                        </div>
                    </span>
                    <span :class="lineItem.transType" class="text-right">
                        <div v-if="lineItem.debit.toLowerCase() == 'credit'">
                            <span class="text-danger">(${{ numbro(lineItem.amount).format() }})</span>
                        </div>
                        <div v-else>
                            ${{ numbro(lineItem.amount).format() }}
                        </div>

                    </span>
                </b-list-group-item>

                <b-list-group-item class="d-flex" style="border-top: 1px double black;">
                    <span class="flex-grow-1 text-right">
                        <h6>Sub Total</h6>
                    </span>
                    <h6 class="text-right money-totals">${{ numbro(subTotal).format() }}</h6>
                </b-list-group-item>
                <b-list-group-item class="d-flex">
                    <span class="flex-grow-1 text-right">
                        <h6>Tax</h6>
                    </span>
                    <h6 class="text-right money-totals">${{ numbro(tax).format() }}</h6>
                </b-list-group-item>
                <b-list-group-item class="d-flex">
                    <span class="flex-grow-1 text-right">
                        <h3>Total</h3>
                    </span>
                    <span class="text-right money-totals">
                        <h3>${{ numbro(total).format() }}</h3>
                    </span>
                </b-list-group-item>
                <b-list-group-item class="d-flex">
                    <span class="flex-grow-1 text-right">
                        <span>Total of payments recieved</span>
                    </span>
                    <span class="text-right money-totals">${{ numbro(totalPayments).format() }}</span>
                </b-list-group-item>
                <b-list-group-item class="d-flex" style="border-top: 1px double black;">

                    <span class="flex-grow-1 text-right">
                        <h3>Balance</h3>
                    </span>
                    <span class="text-right money-totals">
                        <h3>${{ numbro(balance).format() }}</h3>
                    </span>
                </b-list-group-item>

            </b-list-group>
            <!-- End Money Line Items -->
        </b-card>

    </div>
</template>

<script>
import numbro from "numbro"
import { fromUnixTime, format } from "date-fns"

export default {
    name: 'portal-money',
    props: {
        jobId: String,
        contract: Object
    },
    components: {

    },
    data() {
        return {
            fromUnixTime,
            format,
            money: [],
            numbro: numbro,
            pin: 'A569'
        }
    },
    computed: {
        subTotal() {
            let sub = 0
            this.money.forEach(trans => {
                if (trans.debit.toLowerCase() == 'debit') {
                    if (trans.sales.tax) {
                        sub = numbro(sub).add(numbro(trans.amount).subtract(trans.sales.tax))
                    } else {
                        sub = numbro(sub).add(trans.amount)
                    }
                }
            })
            return sub
        },
        tax() {
            let tax = 0
            this.money.forEach(trans => {
                if (trans.debit.toLowerCase() == 'debit') {
                    if (trans.sales.tax) {
                        tax = numbro(tax).add(trans.sales.tax)
                    }
                }
            })
            return tax
        },
        total() {
            let newTotal = numbro(this.subTotal).add(this.tax)
            this.$actions.updateTotal(newTotal)
            return newTotal
        },
        totalPayments() {
            let payments = 0
            this.money.forEach(trans => {
                if (trans.debit.toLowerCase() == 'credit') {
                    payments = numbro(payments).add(trans.amount)
                }
            })

            return payments
        },
        balance() {
            return numbro(this.total).subtract(this.totalPayments)
        }
    },
    methods: {
        async getMoney() {
            try {
                console.log("getMoney called, making api call to portal...")
                let res = await this.$axios.get(`/money/portal/${this.jobId}/${this.pin}`)

                this.money = res.data
                console.log(res.data)

            } catch (err) {
                console.error(err)
            }
        }, // --End of getMoney method

        addUpSales(item) {
            let roofing = this[item].sales.roofing ? this[item].sales.roofing : 0
            let siding = this[item].sales.siding ? this[item].sales.siding : 0
            let windows = this[item].sales.windows ? this[item].sales.windows : 0
            let gutters = this[item].sales.gutters ? this[item].sales.gutters : 0
            let permits = this[item].sales.permits ? this[item].sales.permits : 0
            let creditCard = this[item].sales.creditCard ? this[item].sales.creditCard : 0
            let tax = this[item].sales.tax ? this[item].sales.tax : 0

            let total = roofing + siding + windows + gutters + permits + creditCard + tax

            this[item].amount = total.toString()

        },
        calcTax(item) {
            let roofing = this[item].sales.roofing ? this[item].sales.roofing : 0
            let siding = this[item].sales.siding ? this[item].sales.siding : 0
            let windows = this[item].sales.windows ? this[item].sales.windows : 0
            let gutters = this[item].sales.gutters ? this[item].sales.gutters : 0
            let permits = this[item].sales.permits ? this[item].sales.permits : 0
            let creditCard = this[item].sales.creditCard ? this[item].sales.creditCard : 0

            let totalWithoutTax = roofing + siding + windows + gutters + permits + creditCard

            let salesTaxRate = numbro(this.company.salesTaxRate)

            let calculatedTax = totalWithoutTax * (salesTaxRate / 100)

            console.log(`tax calcualted as ${calculatedTax}`)

            this[item].sales.tax = Number(numbro(calculatedTax).format({ mantissa: 2 }))

            this.addUpSales(item)
        },
        showInvoice() {
            this.$router.push({ name: 'viewInvoice', params: { id: this.jobId } })
        }
    },
    created() {
        numbro.setDefaults({
            thousandSeparated: true,
            mantissa: 2
        })

        this.getMoney()
    }
}
</script>

<style lang="scss" scoped>
.custom-green {
    color: white;
    background-color: #95bf8f; //dark sea green
}

.lightTxt {
    color: gray;
    font-size: .8em;

    div {
        margin-left: .25em;
    }
}

.debit {
    font-weight: bold;
}

.credit {
    color: #dc3545;
}

.credit:before {
    content: "-";
}

.money-totals {
    width: 18%;
}

@media only screen and (max-width: 600px) {
    .money-totals {
        width: 60%;
    }
}

.hover-hand:hover {
    cursor: pointer;
}

.rightJustified {
    text-align: right;
}

.card-header {
    padding: .5rem 1.25rem
}

.ccRow {
    padding: .5rem 1rem;
    font-size: .9em
}

.ccHeaderRow {
    font-weight: bold;
    font-size: .8em;
}

.txtMsg {
    max-width: 375px;
    word-wrap: break-word;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 25px;
    }
}

.send {
    color: white;
    background: #5BC236;
    align-self: flex-end;

    &:before {
        right: -7px;
        width: 20px;
        background-color: #5BC236;
        border-bottom-left-radius: 16px 14px;
    }

    &:after {
        right: -26px;
        width: 26px;
        background-color: white;
        border-bottom-left-radius: 10px;
    }
}
</style>