
<template>
    <b-container :class="[isMobile ? 'mt-5 pt-3 mb-5 pb-4' : 'mt-3' ]">
        <b-card class="mb-3" no-body border-variant="primary" header-bg-variant="primary" header-text-variant="light">
            <template #header>
                <span class="monoTitle"><b>Repeat Jobs</b></span>
            </template>
            <b-list-group flush>
                <b-list-group-item>
                    <b-row no-gutters>
                        <b-col cols="8" md="5" class="text-truncate d-inline-block monoTitle" >
                            <strong>Template Job Name</strong>
                        </b-col>
                        <b-col cols="4" md="2" class="text-truncate d-inline-block monoTitle" >
                            <strong>Job Type</strong>
                        </b-col>
                        <b-col cols="4" md="3" class="text-truncate d-inline-block monoTitle" >
                            <strong>Schedule</strong>
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block monoTitle" >
                            <strong>Status</strong>
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block monoTitle" >
                            <strong>Next</strong>
                        </b-col>

                    </b-row>
                </b-list-group-item>
                <b-list-group-item v-for="scheduled in repeatJobs" :key="scheduled._id">
                    <b-row no-gutters>
                        <b-col cols="8" md="5" class="text-truncate d-inline-block monoTitle" >
                            <a v-if="scheduled.job !== null" :href="`/jobProfile/${scheduled.job._id}`"><font-awesome-icon icon="fa-regular fa-pen-to-square" /> {{scheduled.job.customer.lname}}, {{scheduled.job.customer.fname}}</a>
                            <span v-else>Missing job! - {{ scheduled._id }}</span>
                        </b-col>
                        <b-col v-if="scheduled.job !== null" cols="4" md="2" class="text-truncate d-inline-block" >
                            {{scheduled.job.jobType}}
                        </b-col>
                        <b-col cols="4" md="3" class="text-wrap d-inline-block" :class="{strike: !scheduled.active}" >
                            {{scheduled.formattedDays}}
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block" :class="[scheduled.active ? 'text-success' : 'text-danger']">
                            {{scheduled.active ? 'Active' : 'Disabled'}}
                        </b-col>
                        <b-col cols="4" md="1" class="text-truncate d-inline-block" >
                                {{scheduled.active ? `${scheduled.nextDate} days` : 'n/a' }}
                        </b-col>

                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </b-card>
    </b-container>
  </template>
  
  <script>
  import { format } from 'date-fns'
  
  export default {
    name: "repeatJobs",
    components: {
      
    },
    data() {
      return {
        repeatJobs: []
      }
    },
    computed: {
          userInfo () {
            return this.$state.userInfo
          },
          isMobile () {
            return this.$state.isMobile
          }
    },
    methods: {
        async getRepeatJobs() {
            try {
                const res = await this.$axios.get('/repeatJob/', {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                //console.log(res.data)

                res.data.forEach(async job => {
                    job.formattedDaysArray = []
                    job.formattedDays = ""
                    job.nextDate = ""
                    
                    let prettyDaysOfYear = () => {
                        return new Promise((resolve, reject) => {
                            try {
                                for(let i=0; i < job.daysOfYear.length; i++) {
                                    //job.formattedDaysArray.push(this.dateFromDay(job.daysOfYear[i]))
                                    let formattedDay = format(this.dateFromDay(job.daysOfYear[i]), 'MM/dd/yyyy')
                                    job.formattedDaysArray.push(formattedDay)
                                }
                                job.formattedDays = job.formattedDaysArray.join(', ')
                                resolve("Looping over daysOfYear completed.")    

                            } catch (err) {
                                reject(err)
                            }
                            
                        })
                    } 
                

                    try {
                        await prettyDaysOfYear()
                        //console.log(msg)
                    } catch (err) {
                        console.log(err)
                    }

                    //get the closest future date...
                    //from chatGPT
                    // Function to get the current day of the year
                    const getCurrentDayOfYear = () => {
                        const now = new Date()
                        const start = new Date(now.getFullYear(), 0, 1) // January 1st
                        const diff = now - start // Difference in milliseconds
                        return Math.floor(diff / (1000 * 60 * 60 * 24)) + 1 // Convert to days
                    }
                    const today = getCurrentDayOfYear()
                    
                    let daysOfYear = job.daysOfYear
                    
                    // Find the nearest day in the list
                    const nearestDay = daysOfYear
                        .filter(day => day >= today) // Filter days that are in the future or today
                        .sort((a, b) => a - b)[0] || Math.min(...daysOfYear) + 365 // Handle wrapping to next year

                    // Calculate the difference in days
                    const daysUntilNearest = nearestDay >= today 
                        ? nearestDay - today 
                        : nearestDay + 365 - today

                    
                    job.nextDate = daysUntilNearest
                    
                    //console.log(`Current day of the year: ${today}`)
                    //console.log(daysOfYear)
                    //console.log(`Nearest day in the list: ${nearestDay}`);
                    //console.log(`Days until the nearest day: ${daysUntilNearest}`);
                })

                this.repeatJobs = res.data
                

            } catch (err) {
                console.error(err)
            }
        },
        dateFromDay(day){
            let year = new Date().getFullYear()
            let date = new Date(year, 0); // initialize a date in `year-01-01`
            return new Date(date.setDate(day)); // add the number of days
        }
    },
    created() {
        this.getRepeatJobs()
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  .strike {
    text-decoration: line-through;
  }
  </style>
  