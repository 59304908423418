<template>
    <div>
        <b-nav class="mobileNav" justified>
            <b-nav-item class="text-left">
                <img src="/img/navLogo.png" class="mobileLogo" />
                <div class="d-inline ml-1 monoTitle navTitle"> TWIN</div>
            </b-nav-item>

        </b-nav>
        <b-container class="mt-5 pt-3">

            <h3 class="monoTitle">{{ customer.lname }}, {{ customer.fname }}</h3>
            <strong>Billing:</strong> {{ customer.address.street }}, {{ customer.address.city }} <br />

            <b-button class="mt-2" variant="primary" @click="download('contract')">
                <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" /> Download Contract
            </b-button>
            <b-button class="mt-2 ml-1" variant="danger" @click="download('invoice')">
                <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" /> Download Invoice
            </b-button>

            <b-card header-text-variant="light" border-variant="primary" class="mt-3" header-bg-variant="primary">

                <template #header>
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="mb-0 monoTitle">Contract Details
                            <small>
                                <b-badge :variant="soldBadge" v-html="getStatus(contract.status)"></b-badge>
                            </small>
                        </h5>
                    </div>
                </template>

                <b-card-text>

                    <b-row>
                        <b-col sm="6">
                            <h5>Contract # {{ contract.contractId }} | {{ contract.jobType }}</h5>
                            <h5 class="text-info" v-if="contract.jobLabel">{{ contract.jobLabel }}</h5>
                            <address>
                                <b>Jobsite Address</b> <br />
                                {{ contract.siteAddress.street }}<br />
                                {{ contract.siteAddress.city }}, {{ contract.siteAddress.state }} {{
                contract.siteAddress.zip }}
                            </address>

                            <div class="mb-2">
                                Salesman: {{ contract.salesman.name }} <br />
                            </div>
                        </b-col>
                        <b-col sm="6">
                            <b-img :src="streetViewUrl" fluid class="border border-primary rounded mb-2"></b-img>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" sm="3">
                            <b-card class="contractInfoCard" body-class="date-card-body"
                                header-class="date-card-header monoTitle" align="center" header="Contract Date"
                                border-variant="dark">
                                <b-card-text>{{ contract.dates.contractDate }}</b-card-text>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="3">
                            <b-card class="contractInfoCard" body-class="date-card-body"
                                header-class="date-card-header monoTitle" align="center" header="Sold"
                                border-variant="dark">
                                <b-card-text>{{ contract.sold ? contract.dates.depositDate : "n/a" }}</b-card-text>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="3">
                            <b-card class="contractInfoCard" body-class="date-card-body"
                                header-class="date-card-header monoTitle" align="center" header="Started"
                                border-variant="dark">
                                <b-card-text>{{ contract.sold ? contract.dates.startDate : "n/a" }}</b-card-text>
                            </b-card>
                        </b-col>
                        <b-col cols="6" sm="3">
                            <b-card class="contractInfoCard" body-class="date-card-body"
                                header-class="date-card-header monoTitle" align="center" header="Complete"
                                border-variant="dark">
                                <b-card-text>{{ contract.sold ? contract.dates.finishedDate : "n/a" }}</b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card-text>
                <div class="mb-3"><b>Proposal text:</b><br />{{ cleanScopeText }} <a href="#">...view more</a></div>
            </b-card>

            <money :jobId="id" :contract="contract" ></money>

        </b-container>
    </div>
</template>

<script>
import { format, fromUnixTime } from 'date-fns'
import numbro from 'numbro'
import fileDownload from 'js-file-download'
import money from '../components/customerPortal-components/money.vue'

export default {
    name: 'customerPortal',
    components: {
        money
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        /*
        isMobile() {
            return this.$state.isMobile
        },
        */
        cleanScopeText() {
            if (this.contract.scopeText) {
                let tempText = this.contract.scopeText.replaceAll(/(<([^>]+)>)/gi, "").replaceAll('&bull;', "").replaceAll('&quot;', "").replaceAll('&nbsp;', "")
                return tempText.substring(0, 240)
            } else {
                return ""
            }
        }
    },
    data() {
        return {
            contractName: '',
            invoiceName: "",
            customer: {
                address: {
                    street: ""
                }
            },
            contract: {
                siteAddress: {
                    street: ""
                },
                salesman: "",
                dates: {
                    contractDate: ""
                }
            },
            pin: 'A569',
            soldBadge: "",
            streetViewUrl: "",
            streetViewDate: null
        }
    },
    methods: {
        async getInfo() {
            console.log(`getInfo Called...id is: ${this.id}`)

            try {
                const res = await this.$axios.get(`/job/portal/${this.id}/${this.pin}`)

                //formatDates
                res.data.dates.contractDate = format(fromUnixTime(res.data.dates.contractDate), "MM/dd/yyyy")

                if (!res.data.dates.depositDate) {
                    res.data.dates.depositDate = "n/a"
                } else {
                    res.data.dates.depositDate = format(fromUnixTime(res.data.dates.depositDate), "MM/dd/yyyy")
                }

                if (!res.data.dates.startDate) {
                    res.data.dates.startDate = "n/a"
                } else {
                    res.data.dates.startDate = format(fromUnixTime(res.data.dates.startDate), "MM/dd/yyyy")
                }

                if (!res.data.dates.finishedDate) {
                    res.data.dates.finishedDate = "n/a"
                } else {
                    res.data.dates.finishedDate = format(fromUnixTime(res.data.dates.finishedDate), "MM/dd/yyyy")
                }

                this.contract = res.data
                this.customer = res.data.customer
                console.log(`Customer Portal Contract data loaded.`)
                this.getStreetView()

            } catch (err) {
                console.error(err)
            }

        },
        async getOtherContracts(id) {
            try {
                const res = await this.$axios.get(`/job/getAllJobs/${id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                let filteredRes = res.data.filter((job) => {
                    return job._id != this.id
                })

                this.contracts = filteredRes

            } catch (err) {
                console.error(err)

            }
        },
        async download(doc) {
            const docToDownloadName = doc === 'invoice' ? this.invoiceName : this.contractName
            this.downloaded = true
            let fileData = {
                name: docToDownloadName,
                contractId: this.contract.contractId,
                type: doc
            }

            try {
                const res = await this.$axios.put(`/download`, fileData, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                    responseType: 'blob'
                })
                console.log(res)
                fileDownload(res.data, fileData.name)

            } catch (err) {
                console.log("Downlaod Error", err)
            }
        },
        async getStreetView(){
            let apiKey = "AIzaSyDZbo8X_q8crHijPzXsjD6krj2luf-00Jw"
            let metaUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?location=${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}&key=${apiKey}`
            let streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?location=${this.contract.siteAddress.street}, ${this.contract.siteAddress.city} ${this.contract.siteAddress.state}&size=300x135&key=${apiKey}`
            
            try {
                let res = await this.$axios.get(metaUrl)
                if(res.data.status == "OK") {
                    this.streetViewDate = res.data.date
                    this.streetViewUrl = streetViewUrl
                } else {
                    this.streetViewDate = "No Data"
                    this.streetViewUrl = "https://via.placeholder.com/300x135?text=No+StreetView+Available"
                }
            } catch (err) {
                console.log("Error getting streetview metadata")
            }
        },
        getStatus(status) {
            if (this.contract.sold) {
                this.soldBadge = "danger"
                return status
            } else {
                this.soldBadge = "warning"
                return `Un-Sold`
            }
        }
    },
    created() {
        this.getInfo()
        this.$state.showNav = false
        numbro.setDefaults({
            thousandSeparated: true,
            mantissa: 2
        })

        
    }
}
</script>

<style lang="scss" scoped>
    .mobileNav {
        display: flex;
        justify-content: space-between;
        background-color: #017BFE;
        padding: 0 0 0 0;
        color: #fff;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        height: 3.5em;
    }

    .navTitle {
        font-size: 2 em;
    }
    .mobileNav a {
        color: #fff;
    }

    .mobileLogo {
        width: 40px;
        height: 40px;
    }

    .date-card-body {
        padding: 5px;
    }

    .date-card-header {
        padding: 5px;
    }
</style>